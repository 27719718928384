@import "fonts";
@import "variables";
@import "productCarousel";

// Quick links

.Quick-links {
  width: 100%;
  margin: 30px auto;

  @include media-breakpoint-up(lg) {
    height: 100px;
    overflow: hidden;
  }
  ul {
    margin: 0 auto;
    padding: 0 20px;
    @include media-breakpoint-up(xl) {
      margin: 0 100px;
    }
    .line-item {
      margin-right: 0px;
      vertical-align: top;
      display: inline-block;
      &.slick-slide {
        display: block;
      }
      @include media-breakpoint-down(md) {
        &.slick-slide.slick-current {
          margin-right: 0px;
        }
      }
      @include media-breakpoint-up(xl) {
        width: 16%;
        padding-left: 15px;
        padding-right: 15px;
      }
      li {
        list-style-type: none;
        @include media-breakpoint-down(md) {
          box-shadow: 0px 2px 6px #00000029;
          border: 1px solid #ced4da;
          border-radius: 20px;
          width: auto;
          height: 130px;
          padding: 15px;
          margin-left: 8px;
          margin-right: 15px;
          box-sizing: border-box;
          margin-bottom: 2px;
        }
        .icon {
          width: 45px;
          display: block;
          height: 45px;
          margin: 0 auto;
          border: 1px solid #ced4da;
          border-radius: 50%;
          &:hover {
            border: 1px solid $primary;
          }
          img {
            display: block;
            width: 24px;
            height: 24px;
            margin: 10px auto;
          }
        }
        .icon-title {
          text-decoration: none;
          max-width: 105px;
          display: block;
          margin: 10px auto;
          text-align: center;
          letter-spacing: 0.26px;
          @include fonts(d, $font-cisco-regular, 12px, 16px);
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .slick-prev {
      left: 16px;
      top: 50%;
      z-index: 1;
      background-color: $white;
      width: 30px;
      height: 30px;
      border-radius: 55%;
      &:hover {
        background-color: $primary;
      }
    }
    .slick-next {
      right: 20px;
      top: 50%;
      background-color: $white;
      width: 30px;
      height: 30px;
      border-radius: 55%;
      &:hover {
        background-color: $primary;
      }
    }

    .slick-prev:before {
      content: "\f053";
      color: $primary;
      font-size: 26px;
      font-family: "Font Awesome\ 5 Free";
      font-weight: 900;
    }

    .slick-next:before {
      content: "\f054";
      font-size: 26px;
      color: $primary;
      font-family: "Font Awesome\ 5 Free";
      font-weight: 900;
    }

    .slick-next:hover::before,
    .slick-prev:hover::before {
      color: $white !important;
    }
  }
}

.container.home-categories {
  padding-left: 0;
  padding-right: 0;
}
header {
  ~ #maincontent {
    .container {
      .Quick-links {
        .icon-title {
          color: $gray-900 !important;
        }
      }
      &.home-product-tiles {
        .pdp-link {
          position: relative;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-height: 16px; 
          min-height: 56px; 
          -webkit-line-clamp: 3; 
          -webkit-box-orient: vertical;
          
          a {
            color: $black !important;
            &:hover {
              color: $primary !important;
            }
          }
        }
        .course-details-credits {
          position: relative;
          .info-icon {
            background: url(../images/status-info-circle.svg) no-repeat right;
            width: 13px;
            height: 13px;
            display: inline-flex;
            top: 1px;
            vertical-align: middle;
            position: static;
            .tooltip {
              color: $black;
              min-width: 95%;
              cursor: auto;
            }
          }
          p {
            display: inline-block;
            @include fonts(d, $font-cisco-regular, 12px, 16px);
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
