
// @import "../sfcc/node_modules/font-awesome/font/fontawesome-webfont.ttf";
@font-face {
    font-family: 'CiscoSans-Medium';
    // src: url('../fonts/CiscoSans-Medium.otf') format('otf'),
    //      url('../fonts/CiscoSans-Medium.ttf') format('truetype'),
    //      url('../fonts/CiscoSans-Medium.woff') format('woff'),
    //      url('../fonts/CiscoSans-Medium.woff2') format('woff2');
    src: url('../fonts/CiscoSansRegular.otf') format('otf'),
         url('../fonts/CiscoSansRegular.ttf') format('truetype'),
         url('../fonts/CiscoSansRegular.woff') format('woff'),
         url('../fonts/CiscoSansRegular.woff2') format('woff2');
}

@font-face {
    font-family: 'CiscoSansBold';
    src: url('../fonts/CiscoSansBold.otf') format('otf'),
         url('../fonts/CiscoSansBold.ttf') format('truetype'),
         url('../fonts/CiscoSansBold.woff') format('woff'),
         url('../fonts/CiscoSansBold.woff2') format('woff2');
}
@font-face {
    font-family: 'CiscoSansBoldOblique';
    src: url('../fonts/CiscoSansBoldOblique.otf') format('otf'),
         url('../fonts/CiscoSansBoldOblique.ttf') format('truetype'),
         url('../fonts/CiscoSansBoldOblique.woff') format('woff'),
         url('../fonts/CiscoSansBoldOblique.woff2') format('woff2');
}

@font-face {
    font-family: 'CiscoSansExtraLight';
    src: url('../fonts/CiscoSansExtraLight.otf') format('otf'),
         url('../fonts/CiscoSansExtraLight.ttf') format('truetype'),
         url('../fonts/CiscoSansExtraLight.woff') format('woff'),
         url('../fonts/CiscoSansExtraLight.woff2') format('woff2');
}
@font-face {
    font-family: 'CiscoSansExtraLightOblique';
    src: url('../fonts/CiscoSansExtraLightOblique.otf') format('otf'),
         url('../fonts/CiscoSansExtraLightOblique.ttf') format('truetype'),
         url('../fonts/CiscoSansExtraLightOblique.woff') format('woff'),
         url('../fonts/CiscoSansExtraLightOblique.woff2') format('woff2');
}
@font-face {
    font-family: 'CiscoSansHeavy';
    src: url('../fonts/CiscoSansHeavy.otf') format('otf'),
         url('../fonts/CiscoSansHeavy.ttf') format('truetype'),
         url('../fonts/CiscoSansHeavy.woff') format('woff'),
         url('../fonts/CiscoSansHeavy.woff2') format('woff2');
}
@font-face {
    font-family: 'CiscoSansHeavyOblique';
    src: url('../fonts/CiscoSansHeavyOblique.otf') format('otf'),
         url('../fonts/CiscoSansHeavyOblique.ttf') format('truetype'),
         url('../fonts/CiscoSansHeavyOblique.woff') format('woff'),
         url('../fonts/CiscoSansHeavyOblique.woff2') format('woff2');
}
@font-face {
    font-family: 'CiscoSansLight';
    src: url('../fonts/CiscoSansLight.otf') format('otf'),
         url('../fonts/CiscoSansLight.ttf') format('truetype'),
         url('../fonts/CiscoSansLight.woff') format('woff'),
         url('../fonts/CiscoSansLight.woff2') format('woff2');
}
@font-face {
    font-family: 'CiscoSansLightOblique';
    src: url('../fonts/CiscoSansLightOblique.otf') format('otf'),
         url('../fonts/CiscoSansLightOblique.ttf') format('truetype'),
         url('../fonts/CiscoSansLightOblique.woff') format('woff'),
         url('../fonts/CiscoSansLightOblique.woff2') format('woff2');
}
@font-face {
    font-family: 'CiscoSansRegular';
    src: url('../fonts/CiscoSansRegular.otf') format('otf'),
         url('../fonts/CiscoSansRegular.ttf') format('truetype'),
         url('../fonts/CiscoSansRegular.woff') format('woff'),
         url('../fonts/CiscoSansRegular.woff2') format('woff2');
}
@font-face {
    font-family: 'CiscoSansRegularOblique';
    src: url('../fonts/CiscoSansRegularOblique.otf') format('otf'),
         url('../fonts/CiscoSansRegularOblique.ttf') format('truetype'),
         url('../fonts/CiscoSansRegularOblique.woff') format('woff'),
         url('../fonts/CiscoSansRegularOblique.woff') format('woff2');
}
@font-face {
    font-family: 'CiscoSansThin';
    src: url('../fonts/CiscoSansThin.otf') format('otf'),
         url('../fonts/CiscoSansThin.ttf') format('truetype'),
         url('../fonts/CiscoSansThin.woff') format('woff'),
         url('../fonts/CiscoSansThin.woff2') format('woff2');
}
@font-face {
    font-family: 'CiscoSansThinOblique';
    src: url('../fonts/CiscoSansThinOblique.otf') format('otf'),
         url('../fonts/CiscoSansThinOblique.ttf') format('truetype'),
         url('../fonts/CiscoSansThinOblique.woff') format('woff'),
         url('../fonts/CiscoSansThinOblique.woff2') format('woff2');
}

$font-cisco-medium: 'CiscoSans-Medium' !default;
$font-cisco-bold: 'CiscoSansBold';
$font-cisco-boldOblique: 'CiscoSansBoldOblique';
$font-cisco-extraLight: 'CiscoSansExtraLight';
$font-cisco-extraLightOblique: 'CiscoSansExtraLightOblique';
$font-cisco-heavy: 'CiscoSansHeavy';
$font-cisco-heavyOblique: 'CiscoSansHeavyOblique';
$font-cisco-light: 'CiscoSansLight';
$font-cisco-lightOblique: 'CiscoSansLightOblique';
$font-cisco-regular: 'CiscoSansRegular';
$font-cisco-regularOblique: 'CiscoSansRegularOblique';
$font-cisco-thin: 'CiscoSansThin';
$font-cisco-thinOblique: 'CiscoSansThinOblique';