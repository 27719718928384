@import "../fonts";

.brand {
    top: -20px;
    img {
        text-align: center;
        width: 60%;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        @include media-breakpoint-between(md, lg) {
            width: 40%;
         }  

    }
    @include media-breakpoint-down(sm) {
        top: 0;
        width: 90px;
        padding-top: 0;
    }
    @include media-breakpoint-only(md) {
        width: 35%;
        margin-left: -6em;
        padding-top: 0;
    }
    @include media-breakpoint-between(md, lg) {
      top:0;
    }
}

.tagline {
    @include fonts(d, $font-cisco-extraLight, 28px, 26px);
    padding-top: 25px;
    cursor:pointer;

    @include media-breakpoint-down(xs) {
        font-size: 12px;
        width: 90px;
        float: right;
        padding-top: 15px;
        line-height: 12px;
    }
   
    @include media-breakpoint-between(sm, md) {
        font-size: 16px;
        width: 125px;
        float: right;
        padding-top: 15px;
        line-height: 14px;
    }
    @include media-breakpoint-between(md, lg) {
        font-size: 20px;
        float: right;
        padding-top:25px;
        width: auto;
    }
  }

.tagline a{
    color:$black !important;
}
.tagline a{
    text-decoration: none;
}
.navbar-toggler img{
    @include media-breakpoint-between(xs,md){
    width:33px;
    height:30px;
    }
}
.navbar-toggler {
    @include media-breakpoint-down(sm){
        padding:0 10px 0 0;
    }
   
}
ul.user_links li img {
    width: 30px;
    padding: 0;
    @include media-breakpoint-up(lg) {
    	&#seacrh_icon {
    		padding-top: 4px;
    		width: 32px;
    	}
    }
    @include media-breakpoint-down(xs) {
        max-width: 75%;
        padding-top: 20px;
    }
}
ul.user_links {
    width: 160px;
    margin-bottom: 24px;
    @include media-breakpoint-down(sm) {
        width: 80px;
        padding: 18px 0 0 15px;
    }
    @include media-breakpoint-down(xs) {
        width: 50px;
        padding-top: 2px;
    }
}

ul.user_links li {
    display: inline;
    list-style: none;
    padding: 0 0 0 25px;
    cursor: pointer;
    z-index: 1000;
    padding-left: 17px;
}
.user-message {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border-width: 3px;
    padding: 1px 0 0 0; 
    position:absolute;
    color:$blue4;
    top: 8px;
    @include media-breakpoint-down(sm) {
        border: none;
        width:auto;
        height: auto;
        border-radius: initial;
        border-width:initial;
        padding:initial;
        position:initial;
        color:$white;
    }
}
.arc_end {
    border-color:$text-color $text-color $text-color transparent;
    transform: rotate(255deg);
    border-width: 1.5px;
    border-style: solid;
}
.arc_end_sup {
    border-color:$text-color $text-color $text-color transparent;
    transform: rotate(-166deg);
    border-width: 1.5px;
    border-style: solid;
}
.navbar-header .user {
    margin-top: 10px;
    width: 50px;
}

.logged-in {
    display: inline-block;
}
.cui-secondary-dark-button {
    max-width: 140px !important;
}
ul.user_links li {
    display: inline;
}
@include media-breakpoint-up(md) {
	.user_links.login {
		margin-top: -4px;
	}
}
.minicart .minicart-icon {
    color: $primary;
}

.minicart .minicart-quantity {
    background-color: $danger !important;
    top: -4.2em;
    left: 0.6em;
    padding-top:5px;
    width: 1.9em;
    height: 1.9em;
    font-size:12px;
    @include media-breakpoint-down(xs) {
        top: -4em;
        left: 0.4em;
    }
}
.navbar-header .user,
.navbar-header .country-selector,
.navbar-header .search,
.navbar-header .minicart {
    width: 35px;
    height: 27px;
    margin: 1em 0 0 0.5em;
}
.minicart-total{
    padding-top:3px;
    @include media-breakpoint-down(sm) {
        padding-top:0;
    }
}
.minicart-total img {
	height: 24px;
    width: 32px;
    @include media-breakpoint-down(xs) {
        max-width: 80%;
    }
}
#myaccount img{
    width: 20px;
    height: 29px;
}
.navbar-toggler {
    right: 15px;
    top: 0;
}

.close-button {
    font-size: 16px;
    font-family: $font-cisco-regular;
}
.close-mobile-menu img {
    max-width: 16px;
}
.mobile-close-btn {
    width: 100px;
    color: $black;
}

.navbar-header .user .popover {
    left: -110px;
    border-radius: 0px 0px 8px 8px;
    top: 64px;
    width: 320px;
    background-color: $tertiary;
    cursor: context-menu;
}
.logged-in .popover ul li { 
    padding: 5px 5px;
    text-decoration: none;
    display: block;
    padding: 10px 0;
    color: $white;
    font-size:18px;
    cursor: context-menu !important;
}
.popover .login-logout{
    margin-top: 10px;
}
.popover ul.nav {
    display: block;
}
.popover .up-arrow {
    position: absolute;
    top: -15px;
    right: 52%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid $tertiary;
    display: block;
}
.logged-in .popover ul li a {
    color: $white;
}

.user-profile {
    background-color: #ced4da;
    width: 45px;
    height: 40px;
    padding-top: 8px;
    border-radius: 50px;
    position: absolute;
    top: -18px;
    font-size: 14px;
    color: $black;
    font-family: $font-cisco-regular;
}
.loggedin-details {
    border-radius: 5px;
    font-size: 14;
    font-family: $font-cisco-regular;
    color: $black;
}
.user-profile:after {
    position: absolute;
    left: 46px;
    top: 20px;
}
.hide-icon:before {
    display: none;
}
.logged-in .popover {
    width: 158px;
}

.logged-in .popover ul li a {
    text-decoration: none;
}
.logged-in .popover ul li a:hover {
    color: $primary;
}
.hide-option {
    display: none !important;
}
.navbar-header .navbar-toggler {
    outline: none;
    font-size: 1.7em;
}
#navbar_top{
    background-color:$white;
    z-index:1000;
    @include media-breakpoint-down(md){
    border-bottom:1px solid $gray-500;
    }
}
#seacrh_icon{
    @include media-breakpoint-between(md, lg) {
    padding-top:5px;
    }
}
.close_icon_search{
    position: absolute;
    right: 20px;
    top: 20px;
    color:$white;
    font-size: 20px;
    cursor:pointer;
}
.add-arrow::after{
    content: "";
    position: absolute;
    left: 86%;
    top: 65px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid $tertiary;
    @include media-breakpoint-down(md){
        display:none;
        }
        @include media-breakpoint-between(md, lg) {
            left: 82%;
        }
    }

    .logged-in {
        .popover ul li span.user-email  {
            font-size:14px;
        } 
        .logout-btn, .login-btn{
            margin-left:0px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
        li.register a{
            font-size:14px;
            text-decoration:underline !important;
        }
    }
   .sticky {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1000;
      }

 .search-mobile{
    @include media-breakpoint-down(md){
      display:none;
    }
 }    
 
 .succesful_msg{
    display:none;
    transform: translate(-50%, 0%);
    position: absolute;
    left: 50%;
    z-index:998;
	text-align:center;
	width:100%;
	padding:20px 0;
	background-color:$success-1;
    color:$mindnight;
    @include fonts(d, $font-cisco-regular, 18px, 28px);
    @include fonts(t, $font-cisco-regular, 16px, 22px);
    @include fonts(m, $font-cisco-regular, 12px, 14px);
    @include media-breakpoint-down(sm) {
        margin-top:0;  
        padding:20px 20px;
        text-align:left;
        top: 100px;
        transform: translate(-50%, -50%);
    }
    .tick{
        font-size:15px;
        @include media-breakpoint-down(sm) {
        width:10%;
        display: table-cell;
        vertical-align: middle;
        }
    }
    .tick_msg{
        @include media-breakpoint-down(sm) {
          width:90%;
          display: table-cell;
        }
    }
    
}
.add-to-cart-messages{
    display:none !important
}

.header-banner {
  .content {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
     }
}
