.eolwrapper {
    .modal-content {
        border-radius: 10px;
        box-shadow: 0px 1px 8px #00000029;
    }
    .modal-header {
        border-bottom: 0px;
        justify-content: center;
        padding: 20px 0px;
        h4 {
            font-family: $font-cisco-medium;
            font-size: 18px;
            color: $gray-900;
        }
        @include media-breakpoint-down(sm) {
            justify-content: flex-start;
            padding-left: 30px;
        }
    }
    .modal-body {
        padding: 0px 30px 20px 30px;
        text-align: left;
        p {
            font-size: 16px;
            color: $gray-900;
            margin-bottom: 0px;
        }
    }
    .modal-footer {
        border-top: 0px;
        justify-content: center;
        padding-top: 0px;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            .continue-cln {
                margin-top: 20px;
            }
            button {
               min-width:222px;
               display: block;
            }
            button.continue-eol{
                margin-top:20px;
            }
        }
    }
    @include media-breakpoint-up(md) {
        text-align: center;
        .modal-dialog {
            max-width: 600px;
        }
    }
}
