.menu-toggleable-left.in .nav-item .nav-link {
    font-family:$font-cisco-regular;
    font-size:18px;
}
.multilevel-dropdown .dropdown-item .dropdown-link{
    text-decoration:none;
}

.navbar.bg-inverse .navbar-nav .nav-link{
    @include fonts(d, $font-cisco-extraLight, 16px, 22px);
    color:$white !important;
    @include media-breakpoint-down(md) {
        color:$white !important;
        @include fonts(d, $font-cisco-regular, 18px, 25px);
        background: none;
    }

}
.hide-no-link{
    display:none!important;
}
.dropdown-item.active, .dropdown-item:active {
    background:none !important;
}
.navbar-expand-md .navbar-nav {
    justify-content: center;
}


@include media-breakpoint-down(sm){
    .nav-item .fa{
        display:none;
    }
	 
}

@include media-breakpoint-up(md) {
	.navbar-expand-md {
		.navbar-nav {
			justify-content: center;
			.nav-item.dropdown {
				a.nav-link:hover {
					color: $white !important;
					background-color: transparent;
				}
				.dropdown-menu.show {
					.dropdown-link {
						color: $blue1;
						&:hover {
							color: $blue2;
						}
						.fa-angle-right {
							display: none;
						}
					}
				}
				.dropdown-menu {
					top: 96%;
					&:before {
						content: "";
						position: absolute;
						left: 34%;
						top: -15px;
						width: 0;
						height: 0;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-bottom: 15px solid $white;
					}
					li.sub_category {
						>a.dropdown-toggle {
							@include fonts(d, $font-cisco-medium, 16px, 18px, 600);
							@include fonts(t, $font-cisco-medium, 15px, 18px, 600);
							color: $blue1;
							&:hover {
								color: $blue1;
							}
						}
						.dropdown-link {
							@include fonts(d, $font-cisco-regular, 14px, 18px);
							@include fonts(t, $font-cisco-regular, 14px, 18px);
							color: $blue1;
							:hover {
								color: $blue2;
							}
						}
					}
				}
			}
		}
	}
	.multilevel-dropdown {
    .dropdown-item {
        + .dropdown-item {
           		border-top: none;
        	}
    	}
 	}
 	.sub_category ul li.dropdown-item{
  		border-top: none !important;
    	padding-left:0;
	}
	.nav-item .nav-link:hover, .nav-item .nav-link:focus, .nav-item.show .nav-link{
    	background-color: $ocean !important;
 	}
	 .multilevel-dropdown .dropdown-item + .dropdown-it{
		border-top:none !important;
	}

	.navbar-nav{
		li.main-d-category:nth-child(1) ul.dropdown-menu li.sub_category{
			width:25%;
			border:none;
			float:left;
			display:inline;
			clear:none;
			padding-bottom:15px;
		}
		li.main-d-category:nth-child(1) ul.dropdown-menu li:nth-child(1).sub_category{
			clear:both;
			float:left;
			padding-bottom:20px;
		}
		li.main-d-category:nth-child(1) ul.dropdown-menu li:nth-child(2).sub_category{
			clear:both;
			float:left;
	
		}
		li.main-d-category:nth-child(1) ul.dropdown-menu li:nth-child(3).sub_category{
			margin-top: 0;
			padding-top: 0;
			position: absolute;
			left:25%;
		}
		li.main-d-category:nth-child(1) ul.dropdown-menu li:nth-child(4).sub_category{
			position: absolute;
			left: 52%;
		}
		li.main-d-category:nth-child(1) ul.dropdown-menu li:nth-child(5).sub_category{
			margin-top: 0;
			padding-top: 0;
			position: absolute;
			left:75%;
		}
		li:nth-child(6) ul.dropdown-menu li.sub_category{
			width:100%;
			float:left;
			display:inline;
			clear:none;
		}
		li.main-d-category:nth-child(1) ul.dropdown-menu{
			min-width:1000px; 
			min-height: 270px;
			border-radius: 0 0 10px 10px;
			left: -135%;
		 }
		 li.main-d-category:nth-child(2) ul.dropdown-menu{
			width:270px;
			border-radius: 0 0 10px 10px;
			 &.show {
				&:before {
					left: 32% !important;
				}
			}
			li a{
				font-size:14px !important;
			}  
		 }
		 li.main-d-category:nth-child(3) ul.dropdown-menu{
			width:275px;
			left: -65%;
			border-radius: 0 0 10px 10px;
			&.show {
				&:before {
					left: 45% !important;
				}
			}
			li.sub_category{
				padding-bottom:15px;
			}
		 }
	}
	li.sub_category a{
		 font-size:16px;
		 font-family:$font-cisco-medium;
		 
	}
	li.sub_category a:after{
		display:none;
	}
	.sub_category ul{
		padding-left:0;
	}
	
	.sub_category ul li.dropdown-item a{
	   
		font-family:$font-cisco-regular;
		font-size:14px;
	}
	
	 .dropdown-item:hover, .dropdown-item:focus{
		 background-color: $white;
	 }
	 .dropdown-toggle::after{
		 display:none;
	 }
	 .nav-item .nav-link:hover, .nav-item .nav-link:focus, .nav-item.show .nav-link{
		 background-color: $mindnight !important;
	 }
	 ul.dropdown-menu li.sub_category >a{
		cursor:default;
	 } 
	
}
 
@include media-breakpoint-down(sm) {
	.dropdown-link {
		@include fonts(d, $font-cisco-regular, 18px, 25px);
		color: $white;
		:hover {
			color: $primary !important;
		}
	}
	.main-menu.menu-toggleable-left.in {
		background: $mindnight;
		margin-right: 0;
	}
	.navbar.bg-inverse,
	.close-menu {
		background: $mindnight !important;
		.back-button {
			float: left;
    		width: 20px;
    		margin-right: 6px;
		}
		.mobile-close-btn {
			color: $white;
			img {
				max-width: 22px;
			}
		}
		.back button {
			color: $white;
			padding: 0;
		}
		.nav-item {
			.fa.fa-angle-right {
				display: block;
				float: right;
				margin-right: 10px;
			}
			.dropdown-item {
				background: $mindnight;
				padding-right: 15px;
				.dropdown-link {
					color: $white;
				}
			}
		}
		.fa.fa-angle-left {
			float: left;
			color: $white;
			display: block;
			margin-right: 10px;
			font-size: 20px;
		}
	}
	.menu-toggleable-left.in .dropdown-toggle::after {
		display: none;
	}
	.multilevel-dropdown .dropdown-item .dropdown-link {
		padding: 0.425em 0em 0.425em 0;
	}
	.dropdown-item.top-category {
		.fa.fa-angle-right {
			display: none !important;
		}
	}
	ul.dropdown-menu {
		background: $mindnight;
	}
	.menu-toggleable-left.navbar-toggleable-sm.in {
		min-width: 100%;
	}
	.nav-item.dropdown {
		padding-right: 15px;
	}
	.multilevel-dropdown .dropdown-item .dropdown-link:hover{
 	  color: $primary !important;
	}
	.menu-toggleable-left.in li.nav-item:last-child, .multilevel-dropdown li.dropdown-item:last-child {
		border-bottom: 1px solid $gray-300;
	}
	.navbar.bg-inverse .navbar-nav .nav-link:hover {
		color: $primary !important;
	}
}



