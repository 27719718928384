@import "variables";
@import "fonts";

//**********************************************Fonts**********************************************//

//text links font size 18px for 1024 and above
//text links font size 16px for below 1024
@mixin fonts($device, $font-name, $font-size, $line-height, $font-weight: 400) {
    @if $device == d {
        font-family: $font-name;
        font-size: $font-size;
        font-weight: $font-weight;
        line-height: $line-height;
    }
    @if $device == m {
        @include media-breakpoint-only(xs) {
            font-family: $font-name;
            font-size: $font-size;
            font-weight: $font-weight;
            line-height: $line-height;
        }
    }
    @if $device == t {
        @include media-breakpoint-between(sm, md) {
            font-family: $font-name;
            font-size: $font-size;
            font-weight: $font-weight;
            line-height: $line-height;
        }
    }
}

body {
    @include fonts(d, $font-cisco-regular, 14px, 19px);
    @include fonts(t, $font-cisco-regular, 14px, 19px);
    @include fonts(m, $font-cisco-regular, 14px, 19px);
}

// banner and headet title
.banner-text {
    @include fonts(d, $font-cisco-light, 36px, 56px);
    @include fonts(t, $font-cisco-regular, 22px, 44px);
    @include fonts(m, $font-cisco-medium, 18px, 34px);
}

.header-title {
    @include fonts(d, $font-cisco-extraLight, 28px, 56px);
}

h1 {
    @include fonts(d, $font-cisco-regular, 22px, 0);
    @include fonts(t, $font-cisco-regular, 20px, 0);
    @include fonts(m, $font-cisco-regular, 18px, 0);
}

h2 {
    @include fonts(d, $font-cisco-regular, 18px, 0);
    @include fonts(t, $font-cisco-regular, 16px, 0);
    @include fonts(m, $font-cisco-regular, 16px, 0);
}

h3 {
    @include fonts(d, $font-cisco-regular, 16px, 0);
    @include fonts(t, $font-cisco-regular, 16px, 0);
    @include fonts(m, $font-cisco-regular, 14px, 0);
}

h4 {
    @include fonts(d, $font-cisco-regular, 14px, 0);
    @include fonts(t, $font-cisco-regular, 14px, 0);
    @include fonts(m, $font-cisco-regular, 12px, 0);
}

h5 {
    @include fonts(d, $font-cisco-regular, 12px, 0);
}

h6 {
    @include fonts(d, $font-cisco-regular, 10px, 0);
}

// H1 to H6 medium font
h1 {
    &.cln-medium {
        @include fonts(d, $font-cisco-medium, 22px, 0);
        @include fonts(t, $font-cisco-medium, 20px, 0);
        @include fonts(m, $font-cisco-medium, 18px, 0);
    }
}

h2 {
    &.cln-medium {
        @include fonts(d, $font-cisco-medium, 18px, 0);
        @include fonts(t, $font-cisco-medium, 16px, 0);
        @include fonts(m, $font-cisco-medium, 16px, 0);
    }
}

h3 {
    &.cln-medium {
        @include fonts(d, $font-cisco-medium, 16px, 0);
        @include fonts(t, $font-cisco-medium, 16px, 0);
        @include fonts(m, $font-cisco-medium, 14px, 0);
    }
}

h4 {
    &.cln-medium {
        @include fonts(d, $font-cisco-medium, 14px, 0);
        @include fonts(t, $font-cisco-medium, 14px, 0);
        @include fonts(m, $font-cisco-medium, 12px, 0);
    }
}

//**********************************************BUTTONS**********************************************//
$cui-desktop-button-height: 40px;
$cui-mobile-button-height: 34px;
$cui-desktop-button-font-size: 18px;
$cui-mobile-button-font-size: 16px;
$cui-button-min-width: 140px;

/*
* primary button variables
*/
$primary-bg: $primary;
$primary-bg-hover: #33c9ef;
$primary-bg-active: #66d6f3;
$primary-bg-disabled: $white;
$primary-bg-focus: #00bceb;
$primary-text-disabled: #919191;

/*
* buy button variables
*/
$buy-bg: $success;
$buy-bg-hover: #8bcb6e;
$buy-bg-active: #a8d892;
$buy-bg-disabled: $white;
$buy-bg-focus: #6ebe4a;
$buy-text-disabled: #919191;

/*
* secondary button variables
*/
$secondary-bg: $white;
$secondary-bg-hover: #18365a;
$secondary-bg-active: #122843;
$secondary-bg-disabled: $white;
$secondary-bg-focus: $secondary;
$secondary-text-disabled: #919191;

$secondary-dark-bg: $tertiary;
$secondary-dark-bg-hover: #e6e6e6;
$secondary-dark-bg-active: #b3b3b3;
$secondary-dark-bg-disabled: $text-color;
$secondary-dark-bg-focus: $secondary;
$secondary-dark-bg-text-disabled: $white;

$tertiary-active: #6f53bc;

//standard cui button
@mixin cui-button {
    color: $tertiary !important;
    border-radius: 20px;
    font-size: $cui-desktop-button-font-size;
    height: $cui-desktop-button-height;
    @include media-breakpoint-down(md) {
        font-size: $cui-mobile-button-font-size;
        height: $cui-mobile-button-height;
    }
    min-width: $cui-button-min-width;
    width: 100%;
    margin: 0 15px;
    padding: 7px 25px;
}

//primary buttons
.cui-primary-button {
    @include cui-button;
    background-color: $primary !important;
    border-color: transparent !important;
    &:hover {
        background-color: $primary-bg-hover !important;
    }
    &:active {
        background-color: $primary-bg-active !important;
        border-color: $primary-bg-active !important;
    }
    &:disabled,
    &.disabled {
        color: $primary-text-disabled !important;
        background-color: $primary-bg-disabled !important;
        border-color: $black !important ;
        border-style: dotted;
    }
    &:focus {
        box-shadow: none !important;
        outline: 0px !important;
    }
}

//purchase/buy buttons
.cui-buy-button {
    @include cui-button();
    background-color: $buy-bg !important;
    border-color: transparent !important;
    &:hover {
        background-color: $buy-bg-hover !important;
    }
    &:active {
        background-color: $buy-bg-active !important;
        border-color: $buy-bg-active !important;
    }
    &:disabled,
    &.disabled {
        color: $buy-text-disabled !important;
        background-color: $buy-bg-disabled !important;
        border-color: $black !important ;
        border-style: dotted;
    }
    &:focus {
        box-shadow: none !important;
        outline: 0px !important;
    }
}

//secondary buttons
.cui-secondary-button {
    @include cui-button();
    color: $link !important;
    background-color: $secondary-bg !important;
    border-color: $link !important;
    &:hover {
        color: $white !important;
        background-color: $secondary-bg-hover !important;
    }
    &:active {
        color: $white !important;
        background-color: $secondary-bg-active !important;
        border-color: $secondary-bg-active !important;
    }
    &:disabled,
    &.disabled {
        color: $secondary-text-disabled !important;
        background-color: $secondary-bg-disabled !important;
        border-color: $black !important ;
        border-style: dotted;
    }
    &:focus {
        color: $white;
        background-color: $secondary-bg-focus;
        border-color: $secondary-bg-focus !important;
        box-shadow: none !important;
        outline: 0px !important;
    }
}

.cui-secondary-dark-button {
    @include cui-button();
    color: $white !important;
    background-color: transparent !important;
    border-color: $white !important;
    &:active {
        text-decoration: underline;
    }
    &:focus {
        box-shadow: none !important;
        outline: 0px !important;
    }
}

//Tertiary Links

.cui-tertiary-btn {
    color: $link;
    text-decoration: none;
    &:active {
        color: $tertiary-active;
    }
    &:hover {
        color: $tertiary;
    }
}

.disable-btn{
    @include cui-button;
    border: 2px solid $link;
    color:$link !important;
}
.enable-btn{
    @include cui-button;
    background-color: $success !important;
    border:inherit;
}

